<template>
    <div class="card card-custom gutter-b">
        <div class="card-header">
            <div class="card-title">
            <h3 class="card-label">Ubah Password</h3>
            </div>
        </div>
        <div class="card-body">
            <!--begin::Example-->
            <div class="example mb-10">
                <!-- <h4 class="mb-10 font-weight-bold text-dark">Ubah Password</h4> -->
            <!--begin::Input-->
            <div class="form-group row">
                <label class="col-lg-4 col-form-label">Password Lama</label>
                    <div class="col-lg-3">
                        <input type="password" required  v-model="passwordlama" class="form-control"/>
                    </div>
            </div>
            <div class="form-group row">
                <label class="col-lg-4 col-form-label">Password Pengganti</label>
                    <div class="col-lg-3">
                        <input type="password" required v-model="password" class="form-control"/>
                    </div>
            </div>
            <div class="form-group row">
                <label class="col-lg-4 col-form-label">Konfirmasi Password Pengganti</label>
                    <div class="col-lg-3">
                        <input type="password" required v-model="konfirmpassword" class="form-control"/>
                    </div>
            </div>
            <div class="form-group row">
                <label class="col-lg-4 col-form-label"></label>
                <div class="col-lg-8">
                    <button type="button" @click="onsubmit" style="float: right;" class="btn btn-success font-weight-bolder text-uppercase px-9 py-4"><i class="fas fa-save"></i> Simpan</button>
                </div>
            </div>
            </div>
            <!--end::Example-->
        </div>
    </div>
</template>
<script>
import { mapGetters } from "vuex";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import axios from 'axios';
import vSelect from "vue-select";
import 'vue-select/dist/vue-select.css';
import Swal from "sweetalert2";
// @import "vue-select/src/scss/vue-select.scss";

export default {
  components: {vSelect},
  data() {
    return {
    url: localStorage.getItem('baseapi'),
    idprofil: localStorage.getItem('idprofil'),
    user: JSON.parse(localStorage.getItem('user')),
    password:'',
    konfirmpassword:'',
    passwordlama:'',
    token: localStorage.getItem('id_token'),
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Ubah Password" }]);
  },
  methods: {
    onsubmit(){
    if (this.passwordlama != this.user.password_plain) {
        this.$bvToast.toast('Password lama tidak sama, silahkan ulang kembali', {
          title: `Failed `,
          variant: `danger`,
          solid: true
        })
        this.reset()
    } else {
        if (this.password != this.konfirmpassword) {
            this.$bvToast.toast('Password baru tidak sama, silahkan diulang kembali', {
            title: `Failed `,
            variant: `danger`,
            solid: true
            })
            this.reset()
        } else {
            axios
            .put(this.url+'/users/'+ this.user.id, {
            _method: 'patch',
                password:this.password
            },
            {
                headers:{
                "xth": this.token
                }
            })
            .then((res) => {
                this.$bvToast.toast('Password Berhasil Diubah', {
                    title: `Success `,
                    variant: `success`,
                    solid: true
                });this.$router.push({ name: "dashboard" })
                console.log(res.data);
                return res;
                this.reset()
            })
            .catch((err) => {
                console.log(err)
                this.$bvToast.toast('Password Gagal Diubah', {
                title: `Failed `,
                variant: `danger`,
                solid: true
            })
                return err;
                this.reset()
            });
        }
    }
    
    },
    reset(){
        this.passwordlama=""
        this.password=""
        this.konfirmpassword=""
    }
  },
  created(){
    
  }
};
</script>